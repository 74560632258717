.layout-breadcrumb-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //box-shadow: 0px 3px 5px rgb(0 0 0 / 2%), 0px 0px 2px rgb(0 0 0 / 5%), 0px 1px 4px rgb(0 0 0 / 8%);
    border-bottom: 1px solid var(--surface-border);
    padding: .5rem 2rem;
    background-color: var(--surface-card);
    
    .layout-breadcrumb {
        ol {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;
            gap: .5rem;
            flex-wrap: wrap;
            color: var(--text-color-secondary);

            li {
                display: flex;
                align-items: center;
            }
        }
    }
    
    .layout-breadcrumb-buttons {
        display: flex;
        align-items: center;
        gap: .25rem;

        button {
            flex-shrink: 0;
        }
    }
}
