.layout-config-button.p-button {
    z-index: 999;
    position: fixed;
    top: 50%;
    right: 0;
    width: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: .5rem;

    .p-button-icon {
        font-size: 2rem;
    }
}