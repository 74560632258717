/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/avalon/styles/layout/layout.scss";

/* PrimeNG */
@import "assets/primeng/resources/primeng.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/ngx-toastr/toastr.css";

body {
    background-color: white;
}

.k-gradient {
    background: linear-gradient(#fafafa, #eeeeee);
}
.simple-flex-wrapper {
    margin: 0 -10px 0 -10px;
    padding: 8px;
    box-sizing: border-box;
    outline: 0;
    line-height: 1.4285714286;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    background: linear-gradient(#fafafa, #eeeeee);
}
//kento 11.2.0
.k-window-titlebar, .k-dialog-titlebar {
    border-color: inherit;
    color: white;
    background-color: #1274AC;
}
.k-box {
    border: 1px solid #e6e6e6;
    color: #424242;
    margin-bottom: 1rem;
    padding: 1rem;
}
kendo-dropdownlist[readonly=true] > .k-input-inner {
    border: 1px solid #b3e5fc;
}

input:read-only, textarea:read-only {
    border: 1px solid #b3e5fc;
}

.k-label {
    font-weight: 500;
}
.k-switch-md {
    font-size: 12px;
}
.k-chip-solid-tertiary {
    border-color: #007F84;
    color: #424242;
    background-color: #80CBC4;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
// fix k-picker-solid
.k-picker-solid {
    background-color: #ffffff;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05));
}
/* fix layout.css */
.layout-sidebar .layout-menu {
    font-size: 16px !important;
}
.layout-content-wrapper .layout-content {
    padding: 1rem !important;
}
.layout-topbar .layout-topbar-end .layout-topbar-actions-end .layout-topbar-items > li > div {
    min-width: 12rem;
}
// fix avalon root menu item
.layout-sidebar .layout-menu .layout-root-menuitem > .layout-menuitem-root-text {
    display: none !important;
}

.auth-layout-page {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    color: #ffffff;
    background-color: #212121;
    background-image: url( "./assets/primeng/resources/images/login-bg.png" );
    background-position: left center, right bottom;
    background-repeat: repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    .subtitle {
        font-size: 1em;
    }
}

// fix avalon scrollbar width
.layout-menu-container::-webkit-scrollbar {
    width: 4px;
}

.layout-menu-container::-webkit-scrollbar-track {
    background: #ced4da;
}

.layout-menu-container::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border-radius: 20px;
}

.layout-menu-container.layout-menu-dark::-webkit-scrollbar-track {
    background: #9e9e9e;
}

.layout-menu-container.layout-menu-dark::-webkit-scrollbar-thumb {
    background-color: #ced4da;
    border-radius: 20px;
}
// main-toolbar
.main-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 1rem 0;
    background-color: white;
    transition: width 0.3s cubic-bezier(0, 0, 0.2, 1),left 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.main-toolbar-group-start,
.main-toolbar-group-center,
.main-toolbar-group-end {
    display: flex;
    align-items: center;
}

.main-toolbar-group-left,
.main-toolbar-group-right {
    display: flex;
    align-items: center;
}
.main-toolbar-title {
    margin: 0;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.2;
    color: inherit;
    font-size: 1.25rem;
}

@media (max-width: 900px) {
    .main-toolbar {
        flex-direction: column;

        .main-toolbar-group-right {
            margin-top: .5rem;
            text-align: left;
        }
    }
}
.main-toolbar2 {
    background: #efefef;
    border: 1px solid #dee2e6;
    padding: 1rem 1.25rem;
    border-radius: 4px;
    gap: 0.5rem;
}
//layout-topbar-menu-active
@media screen and (max-width: 991px) {
    .layout-container.layout-topbar-menu-active .layout-topbar .layout-topbar-end .layout-topbar-items {
        justify-content: flex-end;
    }
}
//sticky

.k-grid-header, .k-grid-footer {
    padding: 0 !important;
    transition: width 0.3s cubic-bezier(0, 0, 0.2, 1),left 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.k-grid-content.k-virtual-content {
    overflow-y: hidden;
}
.k-window-wrap-dialog .k-grid-content.k-virtual-content {
    overflow-y: auto;
}
@media screen and (min-width: 992px) {
    .sticky-header {
        top: 64px;
        left: 272px;
        position: fixed;
        width: calc(100% - 272px);
        z-index: 990;
        background-color: #e3f2fd;
        padding: 8px 1rem;
    }

    .layout-static-inactive .sticky-header {
        left: 0;
        width: 100%;
    }

    .sticky-header .k-table-thead {
        background-color: #e3f2fd;
    }

    .k-grid-header.sticky-header {
        left: 288px;
        width: calc(100% - 304px);
    }

    .layout-static-inactive .k-grid-header.sticky-header {
        left: 16px;
        width: calc(100% - 32px);
    }

}

// kendo-grid
.officeNg-form-list .k-grid tr.yellow {
    background-color: #fff9c4;
}

.officeNg-form-list .k-grid tr.violet {
    color: #9C27B0;
}

.officeNg-form-list .k-grid tr.red {
    color: red;
}
// fix grid-control
.k-grid th .k-cell-inner .k-link span {
    overflow: hidden;
    text-overflow: ellipsis;
}

kendo-grid-column-chooser > button.k-button-flat {
    border-color: rgba(0, 0, 0, 0.08) !important;
    color: #424242 !important;
    background: linear-gradient(#fafafa, #eeeeee);
}
.k-pager-sizes .k-dropdown, .k-pager-sizes .k-dropdownlist {
    width: 6em !important;
}
// fix checkbox grid-control
.checkbox-column .k-icon {
    /*font-size: 22px;*/
    vertical-align: text-top;
}

/*.checkbox-column .k-icon.k-i-checkbox {
    color: red;
}

.checkbox-column .k-icon.k-i-tri-state-indeterminate {
    color: green;
}*/

// fix checkbox grid-control
.checkbox-column .check-yes {
    color: red;
    font-size: 14px;
    font-weight: 500;
}

.checkbox-column .check-no {
    color: green;
    font-size: 14px;
    font-weight: 500;
}

.checkbox-column {
    text-align: center;
    display: block;
}
// grid loading
.grid-wrapper {
    position: relative;
    min-height: 440px;
}

//fix kendo tabStrip focused
.k-tabstrip-content:focus, .k-tabstrip-content.k-state-focused,
.k-tabstrip > .k-content:focus, .k-tabstrip > .k-content.k-state-focused {
    outline: none;
}
// fix link a grid-control
.k-grid .routerLink-column a {
    color: #0d6efd;
}

.k-grid .routerLink-column a:hover {
    color: #0d15fd;
}

.k-grid .no-padding {
    padding: 0;
}

.whole-cell {
    display: block;
    padding: 8px 12px;
}
//fix grid header font-weight
.k-grid-header .k-headek-grid-header .k-header {
    font-weight: 500;
}

//fix kendo
.k-textarea.k-input {
    overflow-y: scroll;
}

.k-column-list-wrapper .k-column-list-item {
    white-space: nowrap;
}

.k-form .k-form-legend {
    border-bottom: 1px solid #bbdefb;
    color: #0277bd;
}

.k-form-separator.k-form-search-box {
    margin: 0 -10px 0 -10px;
}

.k-grid-toolbar {
    background: linear-gradient(#fafafa, #eeeeee);
}
//fix kendo appbar
.k-appbar {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 2px;
}
//fix
.k-input-md, .k-picker-md {
    font-size: 15px;
}
//fix kendo panel expander-title
.k-expander-title {
    text-transform: none;
}
//fix toastr width
.toast-container .ngx-toastr {
    width: 400px;
}

.toast-container .ngx-toastr.ngx-toastr-width {
    width: 600px;
}
//fix kendo date picker readonly
kendo-dropdownlist[readonly=true] > span {
    background-color: white;
}

kendo-dropdownlist[readonly=true] > button {
    display: none;
}

kendo-numerictextbox[readonly=readonly] > .k-input-spinner {
    display: none;
}

kendo-datepicker[readonly=readonly] > button {
    display: none;
}

kendo-datepicker[readonly=readonly] input:read-only:hover {
    border-color: white;
}
//fix kendo input margin
.k-form .k-form-field {
    margin-top: 0;
}
//fix kendo input shadow
.k-input:focus-within, .k-input:focus, .k-input.k-focus {
    box-shadow: none;
}
// Important to work form animation
.ng-trigger-fadeInAnimation {
    display: block;
}
//
.k-list-item .k-list-item-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
//k-grid
.k-grid td, .k-grid .k-table-td {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
// Fix formly forms
formly-field > formly-wrapper-simple-section {
    margin-bottom: 1em;
    display: block;
}

.k-form-horizontal .k-form-field-wrap {
    max-width: calc(60% - 10px);
    align-self: center;
}

.k-form-horizontal .k-form-field > .k-label,
.k-form-horizontal .k-form-field > kendo-label,
.k-form-horizontal .k-form-field > .k-form-label {
    align-items: flex-start;
    text-align: left;
    width: 40%;
}
.k-form-field.k-form-field-editor .k-form-field-wrap {
    max-width: 100%;
}
.k-form-horizontal formly-field.no-label {
    width: 100%;
    padding: 0.5rem;
}

.k-form-horizontal formly-field.no-label .k-form-field-wrap {
    max-width: calc(100%);
}

.k-label span.k-required {
    display: contents;
    color: red;
}
formly-field + formly-field kendo-expansionpanel {
    margin-top: 1rem;
}
.k-form [class*=col-] {
    padding-bottom: 0 !important;
}
/*
formly-field[class^=col-] + formly-field[class^=col-] {
    padding: 0.5rem 1rem !important;
}

formly-field[class^=col-]:first-child {
    padding-top: 1rem !important;
}

formly-field[class^=col-]:last-child {
    padding-bottom: 1rem !important;
}
*/
.k-upload-max-width .k-form-field-wrap {
    max-width: 100%;
}
//fix kendo paginator
.k-pager-sizes .k-dropdown-list, .k-pager-sizes .k-dropdown, .k-pager-sizes .k-dropdownlist, .k-pager-sizes > select {
    width: 6em;
}

.k-pager-sizes .k-input-inner, .k-pager-sizes .k-input-value-text {
    text-align: center;
}
//fix kendo dialog
.k-dialog {
    .k-dialog-buttongroup {
        &.k-dialog-button-layout-normal {
            display: flex;
            justify-content: flex-end;
        }

        justify-content: flex-end;
    }
}

.k-content.k-window-content.k-dialog-content.k-window-wrap-dialog {
    padding: 0;
}
// fix kento notification
.app-notification {
    font-size: 16px;
    padding: .5rem;
    margin-top: .5rem;
    margin-right: 1rem;
}

.app-notification .k-notification-wrap > .k-icon {
    align-self: center;
}
